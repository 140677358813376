<script setup>
// layout -> bắt buộc
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref } from "vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../service/MethodService";
import DataForm from "./dataForm-cau-hinh-tram";
import toastr from "toastr";
import mushroom from "cem-primary-api";
import { profileUserStore } from "../../stores/profileUser";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const profileUser = profileUserStore();
const router = useRouter();
const route = useRoute();
const rolesUser = ref("");
let loading = ref(true); // loading
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const pageSizeDefault = ref(20); // số bản ghi mặc định ở phân trang

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  fn_LayDanhSachProbeIcon();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  fn_LayDanhSachProbeIcon();
};

const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query; // không phải router
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};

// chuyển sang màn thêm mới tài khoản
const fn_ThemMoiCauHinhIcon = async () => {
  try {
    let nameImage = document.getElementById("upload-icon").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg" &&
        typeImage !== ".svg" &&
        typeImage !== ".ico"
      ) {
        toastr.warning(t("t_select_format_image"));
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("upload-icon").files[0],
        });
        if (res.result) {
          const icon_object = {
            file_id: res.result,
          };
          const newId = await mushroom.icon.createAsync(icon_object);
          if (newId) {
            toastr.success(t("t_upload_success"));
            await fn_LayDanhSachProbeIcon();
          } else toastr.error(t("t_upload_failed"));
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const fn_LayDanhSachProbeIcon = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: "id,file_id,created_time",
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: "-created_time",
  };
  router
    .replace({
      name: "DanhSachProbeIcon",
      query: {
        limit: tableRules.limit,
        page: tableRules.page,
        sort: "-created_time",
        filters: tableRules.filters,
        showFormSearch: tableRules.showFormSearch,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.icon.listAsync(dataFilter);
    if (response.result) {
      const res = await changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};
const changeData = (data) => {
  data.forEach((item) => {
    item.src = mushroom.$file.linkBuilder.thumb.id(item.file_id).build();
  });
  return data;
};
const fn_date = (date) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

const fn_handle = async (type, scope, row) => {
  if (type == "copy") {
    try {
      await navigator.clipboard.writeText(row.file_id);
      const dom = document.getElementById(row.file_id);
      if (dom) {
        dom.classList.remove("hide");
      }
      setTimeout(() => {
        dom.classList.add("hide");
      }, 600);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }
};
onMounted(async () => {
  rolesUser.value = profileUser.roles;
  fn_hashUrl();
  fn_LayDanhSachProbeIcon();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ t("t_option_icon_probe") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <label
              for="upload-icon"
              v-if="rolesUser === 'Admin' || 'AdminPrimary'"
              class="btn btn-secondary btn-border upload"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </label>
            <input
              type="file"
              id="upload-icon"
              style="display: none"
              @change="fn_ThemMoiCauHinhIcon"
            />
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="mt-3 mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            min-height="550"
            v-loading="loading"
            @sort-change="fn_tableSortChange"
            :default-sort="{ prop: 'created_time', order: 'descending' }"
          >
            <el-table-column
              prop=""
              :label="`${$t('t-stt')}`"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="src"
              label="Icon"
              min-width="200"
              align="center"
            >
              <template #default="scope">
                <img
                  :src="scope.row.src"
                  alt=""
                  style="width: 20px; height: 20px"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="file_id"
              label="ID"
              min-width="200"
              align="center"
            />
            <el-table-column
              prop="created_time"
              min-width="200"
              :label="`${$t('t-created-time')}`"
              align="center"
              :sortable="true"
            >
              <template #default="scope">
                {{ fn_date(scope.row.created_time) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="`${$t('t-action')}`"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <button
                    type="button"
                    class="btn btn-soft-info waves-effect waves-light btn-sm"
                    v-b-popover.hover.left="t('action_copy')"
                    @click="fn_handle('copy', scope.$index, scope.row)"
                  >
                    <i class="ri-file-copy-line"></i>
                  </button>
                  <span :id="scope.row.file_id" class="hide">
                    <i class="ri-checkbox-circle-fill"></i>
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped lang="scss">
.upload {
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
}
.hide {
  display: none;
}
.btn-group-thao-tac {
  position: relative;
  span {
    position: absolute;
    right: 30px;
    top: 4px;
    font-size: 17px;
    color: #4ad991;
  }
}
</style>
